.kpi-card-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}

.main-kpi-card-wrapper {
    display: flex;
    flex-flow: row wrap;
}

.main-kpi-card {
    max-width: 450px;
    min-width: 450px;
    border: 1px solid #e4e2e0;
    border-radius: 16px;
    padding-bottom: 32px;
    position: relative;
    margin: 1rem;
    padding: 2rem 2rem 1rem;
    margin-left: 2rem;
}


.kpi-card {
    border: 1px solid #e4e2e0;
    border-radius: 16px;
    padding-bottom: 2px;
    position: relative;
    margin: 1rem;
    padding: 1.3rem 2rem 0rem;
}

.kpi-card h3 {
    display: inline-block;
    color: #085ff7;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
}
.kpi-card h4 {
    font-size: 14px;
    line-height: 1.25;
    padding-bottom: 20px;
    padding-top: 20px;
    font-style: normal;
    font-weight: 700;
}

.kpi-card h1 {
    display: inline-block;
    color: #085ff7;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    font-size: 55px;
}

.main-kpi-card h3 {
    display: inline-block;
    color: #085ff7;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
}
.main-kpi-card h4 {
    font-size: 14px;
    line-height: 1.25;
    padding-bottom: 5px;
    padding-top: 20px;
    font-style: normal;
    font-weight: 700;
}

.main-kpi-card h1 {
    display: inline-block;
    color: #085ff7;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    font-size: 55px;
}

.borderedTopRoleTable {
    border: 1px solid #e4e2e0;
    border-radius: 16px;
}

.btn.btn-secondary {
    color: #5c5a6e;
    background-color: #fff;
    border-color: #5c5a6e;
    -webkit-box-shadow: none;
            box-shadow: none; }
    .btn.btn-secondary:hover, .btn.btn-secondary:active {
      color: #fff !important;
      background-color: #5c5a6e !important;
      border-color: #5c5a6e !important;
      -webkit-box-shadow: 0 14px 26px -12px rgba(142, 141, 162, 0.42), 0 4px 23px 0px rgba(142, 141, 162, 0.12), 0 8px 10px -5px rgba(142, 141, 162, 0.2);
              box-shadow: 0 14px 26px -12px rgba(142, 141, 162, 0.42), 0 4px 23px 0px rgba(142, 141, 162, 0.12), 0 8px 10px -5px rgba(142, 141, 162, 0.2); }
    .btn.btn-secondary:focus {
      color: #fff !important;
      -webkit-box-shadow: none !important;
              box-shadow: none !important;
      background-color: #5c5a6e !important;
      border-color: #5c5a6e !important; }


.formulas{
    border-bottom: solid 1px black;
}

.tableHead{
    color: dimgray;
    font-size: 14px;
}

.tableNumbers{
    color: #085ff7;
}
